<template>
  <el-table v-loading="loading" :data="data">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="Tên người dùng" prop="name">
      <template slot-scope="scope">
        <span>{{ scope.row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Email" prop="email">
      <template slot-scope="scope">
        <span>{{ scope.row.email }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Số điện thoại" prop="phone">
      <template slot-scope="scope">
        <span>{{ scope.row.phone }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Ngày đăng ký" prop="registered_at">
      <template slot-scope="scope">
        <span>{{ scope.row.registered_at }}</span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ListSupport',
  props: {
    data: Array,
    loading: Boolean
  },
  computed: {
    ...mapGetters(['constant']),
    locales() {
      return this.constant.constants.locales
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
